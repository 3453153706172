import anime from "animejs";

export const navMenuItemComponent = async () => {
  jQuery('.nav-menu-item').each((n, el) => {
    const $root = jQuery(el);
    const $target = jQuery(`#${$root.attr('data-target-id')}`);
    let isOpen = false;

    const animateOpen = () => {
      $root.toggleClass('text-black text-red');
      anime({
        targets: $target.get(),
        height: 573,
        duration: 250,
        easing: 'easeOutQuad',
      });
    };

    const animateClose = () => {
      $root.toggleClass('text-black text-red');
      anime({
        targets: $target.get(),
        height: 0,
        duration: 250,
        easing: 'easeOutQuad',
      });
    };

    const toggleOpen = () => {
      isOpen = !isOpen;

      if (isOpen)
        animateOpen();
      else
        animateClose();
    };

    $root.on('click', () => {
      toggleOpen();
    });
  })
};

export const navMenuComponent = async () => {
  jQuery('.nav-menu').each((n, el) => {
    const $root = jQuery(el);
    const $labels = $root.find('.nav-menu-label');
    const $labelsRoot = $root.find('.nav-menu-labels-root');
    const $activeLabelBg = $root.find('.nav-menu-active-label-bg');
    const $targets = $root.find('.nav-menu-category');

    $labels.on('mouseenter', (event) => {
      const $label = jQuery(event.target);
      const $target = $targets.filter(`#${$label.attr('data-target-id')}`);

      $labels.removeClass('text-black font-semibold').addClass('text-gray-dark font-normal');
      $label.addClass('text-black font-semibold').removeClass('text-gray-dark font-normal');

      $targets.addClass('hidden');
      $target.removeClass('hidden');

      anime({
        targets: $activeLabelBg.get(),
        top: $label.offset().top - $labelsRoot.offset().top,
        duration: 250,
        easing: 'easeOutQuad',
      });
      console.log($label.offset().top);
    });
  });
};

export const navMenuAnimatedMobileComponent = async () => {
  jQuery('.nav-menu-item-mobile').each(function() {
    jQuery(this).on('click', function() {
      var targetId = jQuery(this).data('target-id');
      jQuery('#' + targetId).slideToggle();
      jQuery(this).toggleClass('text-red');
    });
  });

  jQuery('.nav-menu-label-mobile').each(function() {
    jQuery(this).on('click', function() {
      var targetId = jQuery(this).data('target-id');
      jQuery('#' + targetId).slideToggle();
    });
  });
}

export const headerComponent = async () => {
  const $root = jQuery('header');

  await navMenuItemComponent();
  await navMenuAnimatedMobileComponent();
  await navMenuComponent();

  jQuery('.header-mobile-toggle').on('click', () => {
    jQuery('.header-mobile').toggleClass('hidden flex');
  });

  jQuery('.my-wuestenrot').on('click', () => {
    jQuery('.my-wuestenrot-menu').toggleClass('hidden flex');
  });
};
